import React from "react";
import "../../assets/css/styles.css";
import "../../assets/rwd/css/min/default.css";

export const Body = () => {
    const currentYear = new Date().getFullYear();
    return (
        <div id="wrapper">
            <div className="header">
                <div className="container">
                    <h1 className="logo">Capital One</h1>
                </div>
            </div>
            <div className="hero">
                <h2>Welcome to<br />
                    <span className="larger">.capitalone</span></h2>
            </div>
            <div className="main">
                <div className="container">
                    <div className="row">
                        <div>
                            <h3>About Capital One</h3>
                            <p>We&rsquo;re a diversified bank that offers an array of financial products and services to individuals, small businesses and commercial clients. But what sets us apart from the other guys is our commitment to innovation and the customer experience. Since day one, we&rsquo;ve harnessed the latest technology to deliver products and experiences that don&rsquo;t just meet our customers&rsquo; needs&mdash;they exceed their expectations.</p>

                            <p>If you are interested in information about our products or services, please visit <a href="http://www.capitalone.com" target="_blank" rel="noopener noreferrer">capitalone.com</a>.</p>
                        </div>

                    </div>


                    <hr />

                    <div className="row">
                        <div className="ten columns alpha content">
                            <p>This is the official Registry Website of the .capitalone top-level domain name and is for the sole and exclusive use of Capital One. While this isn&rsquo;t our main website, we may use it in the future as we look for new and innovative ways to help our customers.</p>
                        </div>

                        <div className="six columns omega aside">
                            <p className="small">Abuse Contact: <br />
                                <a href="mailto:domainmgr@capitalone.com">Domainmgr@capitalone.com</a></p>

                            <p className="small">Abuse Manager: <br />
                                <a href="mailto:ngtld-abuse@cscinfo.com">ngtld-abuse@cscinfo.com</a></p>

                            <p className="small">Capital One CSC<br />
                                ATTN: Abuse Contact<br />
                                251 Little Falls Dr<br />
                                Wilmington, DE 19808 USA</p>

                            <p className="small">ICANN Whois Policy: <br />
                                <a href="https://whois.icann.org" target="_blank" rel="noopener noreferrer">
                                    http://whois.icann.org</a></p>

                            <p className="small">.CAPITALONE Whois: <br />
                                <a href="http://whois.nic.capitalone/" target="_blank" rel="noopener noreferrer">
                                    http://whois.nic.capitalone/</a></p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="container">
                    <div className="twelve columns alpha main-links">
                        <ul className="footer-nav" role="navigation">
                            <li className="footersubs"><a href="https://www.capitalone.com/about">About Us</a>
                                <div className="expand-footersubs"><a href="/#">Expand About Us Menu</a></div>
                                <ul className="footer-display">
                                    <li><a href="https://www.capitalone.com/about">About Capital One</a></li>
                                    <li><a href="https://www.capitalone.com/inclusion">Diversity &amp; Inclusion</a></li>
                                    <li><a href="https://www.capitalone.com/financial-education">Financial Education</a></li>
                                    <li><a title="Link opens in a new window" target="_blank" rel="noopener noreferrer" href="https://www.capitalone.com/investingforgood">Investing for Good</a></li>
                                    <li><a title="Link opens in a new window" target="_blank" rel="noopener noreferrer" className="externalModal external" href="http://phx.corporate-ir.net/phoenix.zhtml?c=70667&p=irol-irhome">Investors</a></li>
                                    <li><a title="Link opens in a new window" target="_blank" rel="noopener noreferrer" className="externalModal external" href="http://press.capitalone.com/phoenix.zhtml?c=251626&p=irol-overview">Press</a></li>
                                </ul>
                            </li>
                            <li className="footersubs"><a href="https://www.capitalone.com/legal/disclosures">Legal</a>
                                <div className="expand-footersubs"><a href="/#">Expand Legal Menu</a></div>
                                <ul className="footer-display">
                                    <li><a href="https://www.capitalone.com/about/military">Servicemembers Civil Relief Act</a></li>
                                    <li><a title="Link opens in a new window" target="_blank" rel="noopener noreferrer" href="https://www.capitalone.com/media/doc/corporate/foreign-bank-account-certification-Capital-One.pdf">Patriot Act Cert</a></li>
                                    <li><a href="https://www.capitalone.com/legal/subpoena-policy">Subpoena Policy</a></li>
                                    <li><a href="https://www.capitalone.com/legal/disclosures">Additional Disclosures</a></li>
                                </ul>
                            </li>
                            <li className="footersubs"><a href="https://www.capitalone.com/contact">Contact Us</a></li>
                            <li className="footersubs"><a href="https://www.capitalone.com/identity-protection/privacy">Privacy</a></li>
                            <li className="footersubs"><a href="https://www.capitalone.com/identity-protection/commitment">Security</a></li>
                            <li className="footersubs"><a href="https://www.capitalone.com/about/accessibility-commitment">Accessibility</a></li>
                        </ul>
                    </div>

                    <div className="four columns omega social-links">
                        <p className="social-media">
                            <a title="Link opens in a new window" target="_blank" rel="noopener noreferrer" className="twitter externalModal external" href="https://twitter.com/capitalone">Twitter</a>
                            <a title="Link opens in a new window" target="_blank" rel="noopener noreferrer" className="facebook externalModal external" href="https://www.facebook.com/capitalone">Facebook</a>
                            <a title="Link opens in a new window" target="_blank" rel="noopener noreferrer" className="youtube externalModal external" href="https://www.youtube.com/user/CapitalOne">YouTube</a>
                            <a title="Link opens in a new window" target="_blank" rel="noopener noreferrer" className="linkedin externalModal external" href="https://www.linkedin.com/company/capital-one">Linked In</a>
                            <a title="Link opens in a new window" target="_blank" rel="noopener noreferrer" className="instagram externalModal external" href="https://www.instagram.com/capitalone/">Instagram</a>
                        </p>
                    </div>
                </div>

                <div className="container">
                    <p className="copyright">Copyright &copy; {currentYear} Capital One</p>
                </div>
            </div>
        </div>
    );
};